import { useAppContext } from "@/context/app"
import { Box } from "@/designSystem/components/box"
import { Icon } from "@/designSystem/components/icon"
import { Mini } from "@/designSystem/components/mini/mini"
import { MainLayout } from "@/designSystem/layouts/mainLayout"
import { Info } from "@/designSystem/patterns/info"
import { Navigation } from "@/designSystem/patterns/navigation"
import { useI18nContext } from "@/i18n/i18n-solid"
import {
	INTERVAL,
	type Interval,
	type SortField,
	createBlogRouter,
} from "@/routes/blog"
import { color } from "@repo/fika-design-system/color"
import { createIntervalCounter } from "@solid-primitives/timer"
import { useNavigate } from "@solidjs/router"
import { For, Show, createMemo, createResource } from "solid-js"
import { z } from "zod"
import {
	cellRecipe,
	layoutClass,
	paginationButtonClass,
	paginationClass,
	paginationIconClass,
	percentageClass,
	rowRecipe,
	tableBodyClass,
	tableClass,
} from "./stats.css"

const PAGE_SIZE = 10

const visitorsSchema = z.array(z.tuple([z.string(), z.number()]))
const visitorsAndViewsSchema = z.array(
	z.tuple([z.string(), z.number(), z.number()]),
)

const dataSchema = z.object({
	byReferrer: visitorsAndViewsSchema,
	byDevice: visitorsSchema,
	byCountry: visitorsSchema,
	byBrowser: visitorsSchema,
})

export function Stats() {
	const navigate = useNavigate()
	const { LL } = useI18nContext()
	const { session } = useAppContext()
	const { paths, statsParams } = createBlogRouter()

	const buttons = createMemo(() => {
		return [
			{
				label: LL().common.blog(),
				href: paths().url,
			} as const,
		]
	})

	const apiParams = createMemo(() => {
		const params: Record<string, string> = {
			interval: statsParams().interval,
		}

		return new URLSearchParams(params)
	})

	const [getStats] = createResource(apiParams, async (params) => {
		const backendUrl = import.meta.env.VITE_FIKA_BACKEND_OPEN_URL

		const response = await fetch(`${backendUrl}/api/table_stats?${params}`, {
			headers: { Authorization: `Bearer ${session.jwt}` },
		})
		const data = await response.json()
		return dataSchema.parse(data)
	})

	return (
		<MainLayout
			header={<Navigation hue="amber" title="stats" buttons={buttons()} />}
		>
			<Box gap="12">
				<Box
					flexDirection="row"
					alignItems="center"
					padding={{ inline: "12", block: "0" }}
					gap="8"
				>
					<Icon name="calendar-clock" size="18" color="sand800" />
					<Mini.Select
						options={Object.values(INTERVAL)}
						value={INTERVAL[statsParams().interval]}
						onChange={(interval) => {
							navigate(
								paths().stats({
									interval: Object.keys(INTERVAL).find(
										(key) => INTERVAL[key as Interval] === interval,
									) as Interval,
								}),
							)
						}}
					/>
				</Box>
				<div class={layoutClass}>
					<Show
						when={!getStats.loading}
						fallback={
							<>
								<LoadingTable field="referrer" withViews />
								<LoadingTable field="browser" />
								<LoadingTable field="country" />
								<LoadingTable field="device" />
							</>
						}
					>
						<>
							<Table field="referrer" data={getStats()?.byReferrer ?? []} />
							<Table field="browser" data={getStats()?.byBrowser ?? []} />
							<Table field="country" data={getStats()?.byCountry ?? []} />
							<Table field="device" data={getStats()?.byDevice ?? []} />
						</>
					</Show>
				</div>
			</Box>
		</MainLayout>
	)
}

function LoadingTable(props: { field: SortField; withViews?: boolean }) {
	const count = createIntervalCounter(100)
	const tuples = createMemo(() => {
		return Array.from({ length: PAGE_SIZE }, (_, i) => {
			const str = i === count() % PAGE_SIZE ? "loading..." : ""

			return props.withViews ? [str, 0, 0] : [str, 0]
		})
	})

	return <Table field="referrer" data={tuples() as TableData[]} />
}

type TableData = [string, number, number] | [string, number]

interface TableProps {
	field: SortField
	data: TableData[]
}

function Table(props: TableProps) {
	const { paths, statsParams } = createBlogRouter()
	const params = createMemo(() => statsParams()[props.field])

	function getPaginationUrl(direction: "next" | "prev") {
		const currentPage = params().page
		const newPage =
			direction === "next" ? currentPage + 1 : Math.max(1, currentPage - 1)

		return paths().stats({
			[props.field]: {
				page: newPage,
			},
		})
	}

	const totalVisitors = createMemo(() =>
		props.data.reduce((acc, item) => acc + item[1], 0),
	)
	const totalViews = createMemo(() =>
		props.data.reduce((acc, item) => acc + (item[2] ?? 0), 0),
	)

	const paginatedData = createMemo(() => {
		const start = (params().page - 1) * PAGE_SIZE
		return props.data.slice(start, start + PAGE_SIZE)
	})

	const hasNextPage = createMemo(() => {
		const start = params().page * PAGE_SIZE
		return start < props.data.length
	})

	const hasPrevPage = createMemo(() => params().page > 1)

	return (
		<Show when={props.data.length > 0} fallback={<Info>no data for</Info>}>
			<div class={tableClass}>
				<div class={tableBodyClass}>
					<div class={rowRecipe({ header: true })}>
						<div class={cellRecipe({ wide: true })}>{props.field}</div>
						<span class={cellRecipe({ wide: false })}>visitors</span>
						<Show when={props.data[0]!.length > 2}>
							<span class={cellRecipe({ wide: false })}>views</span>
						</Show>
					</div>
					<For each={paginatedData()}>
						{(item) => (
							<Row
								value={item[0]}
								views={item[2]}
								visitors={item[1]}
								totalViews={totalViews()}
								totalVisitors={totalVisitors()}
							/>
						)}
					</For>
				</div>
				<Show when={props.data.length > PAGE_SIZE}>
					<div class={paginationClass}>
						<a
							href={hasPrevPage() ? getPaginationUrl("prev") : undefined}
							class={paginationButtonClass}
							aria-disabled={!hasPrevPage()}
						>
							<Icon class={paginationIconClass} name="arrow-left" size="18" />
						</a>
						<a
							href={hasNextPage() ? getPaginationUrl("next") : undefined}
							class={paginationButtonClass}
							aria-disabled={!hasNextPage()}
						>
							<Icon class={paginationIconClass} name="arrow-right" size="18" />
						</a>
					</div>
				</Show>
			</div>
		</Show>
	)
}

interface RowProps {
	value: string
	views?: number
	visitors: number
	totalViews: number
	totalVisitors: number
}

function Row(props: RowProps) {
	const visitorPercentage = createMemo(() => {
		if (props.totalVisitors === 0) return ""

		return ((props.visitors / props.totalVisitors) * 100).toFixed(1)
	})

	function viewPercentage(views: number) {
		if (props.totalViews === 0) return 0

		return ((views / props.totalViews) * 100).toFixed(1)
	}

	return (
		<div class={rowRecipe({ header: false })}>
			<div
				style={{
					background: `linear-gradient(90deg, ${color.amber200} ${visitorPercentage()}%, transparent ${visitorPercentage()}%)`,
				}}
				class={cellRecipe({ wide: true })}
			>
				{props.value}
			</div>
			<Show when={props.views}>
				{(views) => (
					<div class={cellRecipe({ wide: false })}>
						{views()}{" "}
						<span class={percentageClass}>{viewPercentage(views())}%</span>
					</div>
				)}
			</Show>
			<div class={cellRecipe({ wide: false })}>
				{props.visitors}{" "}
				<span class={percentageClass}>{visitorPercentage()}%</span>
			</div>
		</div>
	)
}
