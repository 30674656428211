import { Icon } from "@/designSystem/components/icon"
import { NumberField } from "@kobalte/core/number-field"
import { Select } from "@kobalte/core/select"
import { TextField } from "@kobalte/core/text-field"
import { ToggleGroup } from "@kobalte/core/toggle-group"
import type { IconName } from "@repo/fika-design-system/icon"
import { For, Show } from "solid-js"
import { Box } from "../box"
import {
	buttonGroupClass,
	buttonToggleClass,
	buttonToggleCountClass,
	inputFieldClass,
	labelClass,
	numberFieldDecrementClass,
	numberFieldGroupClass,
	numberFieldIncrementClass,
	selectClass,
	selectContentClass,
	selectItemClass,
	selectItemLabelClass,
	selectListboxClass,
	selectTriggerClass,
} from "./mini.css"

type MiniTextFieldProps = {
	defaultValue?: string
	disabled?: boolean
	label?: string
	onChange?: (value: string) => void
	placeholder?: string
	type?: "text" | "number" | "password" | "email" | "search"
	value?: string
}
function MiniTextField(props: MiniTextFieldProps) {
	return (
		<TextField
			value={props.value}
			onChange={props.onChange}
			defaultValue={props.defaultValue}
			disabled={props.disabled}
		>
			<Show when={props.label}>
				{(label) => (
					<TextField.Label class={labelClass}>{label()}</TextField.Label>
				)}
			</Show>
			<TextField.Input
				class={inputFieldClass}
				type={props.type}
				placeholder={props.placeholder}
				disabled={props.disabled}
			/>
		</TextField>
	)
}

type MiniNumberFieldProps = {
	label?: string
	maxValue?: number
	minValue?: number
	onChange: (value: string) => void
	value?: number
	defaultValue?: number
}
function MiniNumberField(props: MiniNumberFieldProps) {
	return (
		<NumberField
			value={props.value}
			onChange={props.onChange}
			minValue={props.minValue}
			maxValue={props.maxValue}
			defaultValue={props.defaultValue}
		>
			<Show when={props.label}>
				{(label) => (
					<NumberField.Label class={labelClass}>{label()}</NumberField.Label>
				)}
			</Show>
			<div class={numberFieldGroupClass}>
				<NumberField.Input class={inputFieldClass} />
				<NumberField.IncrementTrigger
					aria-label="increment"
					class={numberFieldIncrementClass}
				>
					⯅
				</NumberField.IncrementTrigger>
				<NumberField.DecrementTrigger
					aria-label="decrement"
					class={numberFieldDecrementClass}
				>
					⯆
				</NumberField.DecrementTrigger>
			</div>
		</NumberField>
	)
}

type MiniSelectProps = {
	options: string[]
	value?: string
	defaultValue?: string
	onChange: (value: string | null) => void
	icons?: Record<string, IconName>
}

function MiniSelect(props: MiniSelectProps) {
	return (
		<Select
			options={props.options}
			value={props.value}
			class={selectClass}
			onChange={props.onChange}
			defaultValue={props.defaultValue}
			itemComponent={(iProps) => (
				<Select.Item item={iProps.item} class={selectItemClass}>
					<Show when={props.icons?.[iProps.item.rawValue]}>
						{(iconName) => <Icon name={iconName()} color="sand700" size="18" />}
					</Show>
					<Select.ItemLabel class={selectItemLabelClass}>
						{iProps.item.rawValue}
					</Select.ItemLabel>
					<Select.ItemIndicator>
						<Icon name="check" size="18" color="sand700" />
					</Select.ItemIndicator>
				</Select.Item>
			)}
		>
			<Select.Trigger class={selectTriggerClass}>
				<Select.Value<string>>{(state) => state.selectedOption()}</Select.Value>
				<Select.Icon>
					<Icon name="chevrons-up-down" size="18" color="sand700" />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content class={selectContentClass}>
					<Select.Listbox class={selectListboxClass} />
				</Select.Content>
			</Select.Portal>
		</Select>
	)
}

type MiniToggleGroupValue = {
	value: string
	count?: number
	iconName?: IconName
	title?: string
}
type MiniToggleGroupValuesProps = {
	values: MiniToggleGroupValue[]
}
function MiniToggleGroupValues(props: MiniToggleGroupValuesProps) {
	return (
		<For each={props.values}>
			{(value) => (
				<ToggleGroup.Item
					class={buttonToggleClass}
					aria-label={value.title ?? value.value}
					value={value.value}
				>
					<Show
						when={value.iconName}
						fallback={
							<Box flexDirection="row" alignItems="baseline" gap="4">
								<span>{value.title ?? value.value}</span>
								<Show when={value.count}>
									<span class={buttonToggleCountClass}>{value.count}</span>
								</Show>
							</Box>
						}
					>
						{(iconName) => (
							<Icon
								title={value.title}
								name={iconName()}
								size="18"
								color="sand700"
							/>
						)}
					</Show>
				</ToggleGroup.Item>
			)}
		</For>
	)
}

type MiniToggleGroupProps = {
	value?: string | null
	defaultValue?: string
	onChange: (value: string | null) => void
} & MiniToggleGroupValuesProps
function MiniToggleGroup(props: MiniToggleGroupProps) {
	return (
		<ToggleGroup
			class={buttonGroupClass}
			value={props.value}
			defaultValue={props.defaultValue}
			onChange={props.onChange}
		>
			<MiniToggleGroupValues values={props.values} />
		</ToggleGroup>
	)
}

type MiniToggleGroupMultipleProps = {
	value?: string[]
	defaultValue?: string[]
	onChange: (value: string[]) => void
} & MiniToggleGroupValuesProps
function MiniToggleGroupMultiple(props: MiniToggleGroupMultipleProps) {
	return (
		<ToggleGroup
			class={buttonGroupClass}
			value={props.value}
			defaultValue={props.defaultValue}
			onChange={props.onChange}
			multiple
		>
			<MiniToggleGroupValues values={props.values} />
		</ToggleGroup>
	)
}

function Mini() {}

Mini.TextField = MiniTextField
Mini.NumberField = MiniNumberField
Mini.Select = MiniSelect
Mini.ToggleGroup = MiniToggleGroup
Mini.ToggleGroupMultiple = MiniToggleGroupMultiple

export { Mini }
