import type { IconName } from "@repo/fika-design-system/icon"
import { Show } from "solid-js"
import { Icon } from "../components/icon"
import { Text } from "../components/text"
import { infoClass } from "./info.css"

type Props = {
	children: string
	iconName?: IconName
}
export function Info(props: Props) {
	return (
		<div class={infoClass}>
			<Show when={props.iconName}>
				{(icon) => <Icon name={icon()} size="24" color="sand600" />}
			</Show>
			<Text color="sand700" fontSize="m">
				{props.children}
			</Text>
		</div>
	)
}
