import { defaultProps } from "@/lib/defaultProps"
import type { BorderRadius } from "@repo/fika-design-system/borderRadius"
import type { BorderWidth } from "@repo/fika-design-system/borderWidth"
import type { BoxShadow } from "@repo/fika-design-system/boxShadow"
import type { Color } from "@repo/fika-design-system/color"
import type {
	AlignItems,
	FlexDirection,
	JustifyContent,
	Overflow,
} from "@repo/fika-design-system/css"
import type { Space } from "@repo/fika-design-system/space"
import { type JSX, createMemo, splitProps } from "solid-js"
import { Dynamic } from "solid-js/web"
import { boxRecipe } from "./box.css"

type Tag =
	| "div"
	| "section"
	| "main"
	| "header"
	| "footer"
	| "ul"
	| "li"
	| "nav"
type Props = {
	fill?: boolean
	alignItems?: AlignItems
	justifyContent?: JustifyContent
	flexDirection?: FlexDirection
	alignSelf?: AlignItems
	gap?: Space
	padding?: Space | { block?: Space; inline?: Space }
	borderColor?: Color
	background?: Color
	borderRadius?: BorderRadius
	borderWidth?: BorderWidth
	boxShadow?: BoxShadow
	children: JSX.Element
	overflow?: Overflow
	class?: string
	tag?: Tag
}

export function Box(props: Props) {
	const propsDefault = defaultProps(
		{ flexDirection: "column", tag: "div" },
		props,
	)
	const [cProps, rProps] = splitProps(propsDefault, [
		"children",
		"tag",
		"class",
		"padding",
	])

	const recipeProps = createMemo(() => {
		const padding = cProps.padding

		if (!padding) {
			return rProps
		}

		if (typeof padding === "object") {
			return {
				...rProps,
				paddingBlock: padding.block,
				paddingInline: padding.inline,
			}
		}

		return { ...rProps, padding }
	})

	const className = createMemo(() =>
		[boxRecipe(recipeProps()), cProps.class].filter(Boolean).join(" "),
	)

	return (
		<Dynamic component={cProps.tag} class={className()}>
			{cProps.children}
		</Dynamic>
	)
}
