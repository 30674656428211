const MAX_LENGTH = 80

export function getTitle(title?: string | null) {
	const str = title || "Untitled"

	if (str.length > MAX_LENGTH) {
		return `${str.slice(0, MAX_LENGTH)}…`
	}

	return str
}
