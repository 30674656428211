import { defaultProps } from "@/lib/defaultProps"
import { type Color, color } from "@repo/fika-design-system/color"
import type { IconName } from "@repo/fika-design-system/icon"
import { iconClass } from "./icon.css"

export type IconSize = "18" | "24" | "32"
type Props = {
	name: IconName
	class?: string
	title?: string
	color?: Color
	size?: IconSize
}

export function Icon(props: Props) {
	const defProps = defaultProps({ size: "24" }, props)

	return (
		<svg
			class={[props.class, iconClass].join(" ")}
			width={defProps.size}
			height={defProps.size}
			stroke-width="2"
			stroke-linecap="round"
			fill="none"
			stroke={props.color ? color[props.color] : "currentColor"}
		>
			<title>{props.title ?? props.name}</title>
			<use href={`#${props.name}`} />
		</svg>
	)
}
