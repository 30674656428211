import { stringify as stringifyJsUrl, tryParse as tryParseJsUrl } from "jsurl2"
import { isObjectType } from "remeda"
import { type Accessor, createMemo } from "solid-js"

export interface MatrixParams {
	[key: string]: string | undefined
}

export interface MatrixParamsResult<T extends Record<string, any>> {
	matrixParams: Accessor<T>
	makeURL: (newParams?: Partial<Record<keyof T, T[keyof T]>>) => string
}

export function createMatrixParams<T extends Record<string, any>>(
	segment: () => string | undefined | null,
	defaults: T,
): MatrixParamsResult<T> {
	const matrixParams = createMemo(() => {
		const str = segment()
		const parsed = str ? tryParseJsUrl(str, {}, { deURI: true }) : {}
		return mergeWithDefaults(defaults, parsed)
	})

	function makeURL(newParams?: Partial<Record<keyof T, T[keyof T]>>): string {
		const params = { ...matrixParams(), ...(newParams || {}) }
		const cleanedParams = cleanParams(params, defaults)
		if (Object.keys(cleanedParams).length === 0) return ""

		return `/${stringifyJsUrl(cleanedParams, { rich: true })}`
	}

	return { matrixParams, makeURL }
}

function cleanParams<T extends Record<string, any>>(
	params: Partial<Record<keyof T, any>>,
	defaults: T,
): Partial<T> {
	const result: Partial<T> = {}

	for (const [key, value] of Object.entries(params)) {
		if (!value) continue

		const defaultValue = defaults[key as keyof T]
		if (defaultValue === undefined) {
			result[key as keyof T] = value
			continue
		}

		const cleanedValue = cleanValue(value, defaultValue)
		if (cleanedValue !== undefined) {
			result[key as keyof T] = cleanedValue as T[keyof T]
		}
	}

	return result
}

function cleanValue<T>(value: T, defaultValue: T): T | undefined {
	if (isObjectType(value) && isObjectType(defaultValue)) {
		const cleanedNested = cleanParams<Record<string, any>>(
			value as Record<string, any>,
			defaultValue as Record<string, any>,
		)
		return Object.keys(cleanedNested).length > 0
			? (cleanedNested as T)
			: undefined
	}

	return value !== defaultValue ? value : undefined
}

function mergeWithDefaults<T extends Record<string, any>>(
	defaults: T,
	params: Record<string, any>,
): T {
	const result = { ...defaults }

	for (const [key, value] of Object.entries(params)) {
		if (!(key in defaults)) continue

		if (isObjectType(value) && isObjectType(defaults[key])) {
			result[key as keyof T] = mergeWithDefaults(
				defaults[key],
				value,
			) as T[keyof T]
		} else {
			result[key as keyof T] = value as T[keyof T]
		}
	}

	return result
}
