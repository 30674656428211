import { intersection } from "@/lib/setOperations"
import { ReactiveMap as RMap } from "@solid-primitives/map"
import { ReactiveSet as RSet } from "@solid-primitives/set"
import { createStore, produce } from "solid-js/store"
import type { z } from "zod"
import { BookmarkSchema } from "./models/bookmark"
import { EntrySchema } from "./models/entry"
import { LogSchema } from "./models/log"
import { PostSchema } from "./models/post"
import { PostStatSchema } from "./models/postStat"
import { PublisherSchema } from "./models/publisher"
import { RecentPublisherSchema } from "./models/recentPublisher"
import { StorySchema } from "./models/story"
import { SubscriptionSchema } from "./models/subscription"
import { UserSchema } from "./models/user"

type Config<Type, Attr extends keyof Type> = {
	indexes: Attr[]
	schema: z.Schema<Type>
}

type Id = string
type Indexes<Type, Attr extends keyof Type> = Map<
	Attr,
	RMap<Type[Attr], RSet<Id>>
>
type ById<Type> = { [id: Id]: Type }

export const db = {
	story: createDatabase({
		schema: StorySchema,
		indexes: ["feed_url", "dc_created_at", "url"],
	}),
	log: createDatabase({
		schema: LogSchema,
		indexes: [],
	}),
	subscription: createDatabase({
		schema: SubscriptionSchema,
		indexes: ["publisher_id"],
	}),
	entry: createDatabase({
		schema: EntrySchema,
		indexes: ["story_id", "publisher_id", "published_at"],
	}),
	bookmark: createDatabase({
		schema: BookmarkSchema,
		indexes: ["story_id", "bookmarked_at"],
	}),
	publisher: createDatabase({
		schema: PublisherSchema,
		indexes: ["url"],
	}),
	recent_publisher: createDatabase({
		schema: RecentPublisherSchema,
		indexes: ["publisher_id"],
	}),
	user: createDatabase({
		schema: UserSchema,
		indexes: [],
	}),
	post: createDatabase({
		schema: PostSchema,
		indexes: ["published_at", "bookmark_id"],
	}),
	postStat: createDatabase({
		schema: PostStatSchema,
		indexes: ["id"],
	}),
} as const
export type DB = typeof db
;(globalThis as any).__db = db

export function createDatabase<
	Type extends { id: string },
	Attr extends keyof Type,
>(config: Config<Type, Attr>) {
	const schema = config.schema
	const [byId, setById] = createStore({} as ById<Type>)
	const indexes: Indexes<Type, Attr> = new Map(
		config.indexes.map((i) => [i, new RMap()]),
	)

	function del(id: Id) {
		const entity = get(id)
		if (!entity) return

		setById(
			produce((byId) => {
				delete byId[id]
			}),
		)

		for (const indexName of config.indexes) {
			const index = indexes.get(indexName)!
			const value = entity[indexName]

			index.get(value)!.delete(id)
		}
	}

	function upsert(id: Id, newEntity: Type) {
		const oldEntity = get(id)

		for (const indexName of config.indexes) {
			const index = indexes.get(indexName)!
			const newValue = newEntity[indexName]

			if (oldEntity) {
				const oldValue = oldEntity[indexName]

				if (oldValue !== newValue) {
					index.get(oldValue)!.delete(id)
				}
			}
			const entities = index.get(newValue)

			if (entities) {
				entities.add(id)
			} else {
				index.set(newValue, new RSet([id]))
			}
		}

		setById(id, newEntity)
	}

	function filterBy<K extends Attr>(query: Record<K, Type[K]>): Type[] {
		let entities
		const values = Object.entries(query) as Array<[Attr, Type[Attr]]>

		for (const [attr, value] of values) {
			const entitySet = indexes.get(attr)!.get(value)
			if (!entitySet || entitySet.size === 0) return []

			entities = entities ? intersection(entitySet, entities) : entitySet
		}

		if (!entities) return []
		return [...entities].map((id) => get(id)!)
	}

	function sortBy(attr: Attr, order: "asc" | "desc") {
		const index = indexes.get(attr)!
		return Array.from(index)
			.sort((a, b) => {
				let comp

				if (typeof a[0] === "string" && typeof b[0] === "string") {
					comp = a[0].localeCompare(b[0])
				} else if (typeof a[0] === "number" && typeof b[0] === "number") {
					comp = a[0] - b[0]
				} else if (a[0] instanceof Date && b[0] instanceof Date) {
					comp = a[0].getTime() - b[0].getTime()
				} else if (a[0] === null || a[0] === undefined) {
					comp = -1
				} else if (b[0] === null || b[0] === undefined) {
					comp = 1
				} else {
					comp = 0
				}

				return order === "asc" ? comp : -comp
			})
			.flatMap(([_, set]) => [...set].map(get).filter((v) => !!v))
	}

	function findBy<K extends Attr>(query: Record<K, Type[K]>): Type | null {
		return filterBy(query)[0] || null
	}

	function all() {
		return Object.values(byId)
	}

	function get(id: Id | null) {
		if (!id) return null
		return byId[id] || null
	}

	function empty() {
		return length() === 0
	}

	function length() {
		return Object.keys(byId).length
	}

	return {
		upsert,
		del,
		filterBy,
		findBy,
		sortBy,
		schema,
		all,
		get,
		empty,
		length,
	}
}
