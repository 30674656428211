// TODO: eventually browsers support Set operations
export function intersection<T>(a: Set<T>, b: Set<T>) {
	const [smaller, larger] = a.size <= b.size ? [a, b] : [b, a]
	const c: Set<T> = new Set()

	for (const item of smaller) {
		if (larger.has(item)) {
			c.add(item)
		}
	}

	return c
}

export function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
	const resultSet = new Set<T>()
	for (const element of setA) {
		if (!setB.has(element)) {
			resultSet.add(element)
		}
	}
	return resultSet
}
