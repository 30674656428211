import { AuthProvider } from "@/context/hanko"
import TypesafeI18n from "@/i18n/i18n-solid"
import svgSprite from "@repo/fika-design-system/sprite.svg?raw"
import { setDefaultOptions } from "date-fns"
import { type ParentProps, Show, createSignal, onMount } from "solid-js"
import "./rootLayout.css"
import { useRegisterSW } from "virtual:pwa-register/solid"
import { loadLocale, userLocale } from "@/lib/i18n"
import { enUS, es } from "date-fns/locale"

const dateLocaleMap = {
	en: enUS,
	es,
} as const

export function RootLayout(props: ParentProps) {
	const locale = userLocale()
	const [localeLoaded, setLocaleLoaded] = createSignal(false)

	useRegisterSW({
		immediate: true,
		onRegisteredSW(swUrl, r) {
			if (r) {
				setInterval(async () => {
					if (!(!r.installing && navigator)) return
					if ("connection" in navigator && !navigator.onLine) return

					try {
						const resp = await fetch(swUrl, {
							method: "HEAD",
							cache: "no-store",
							headers: {
								cache: "no-store",
								"cache-control": "no-cache",
							},
						})

						if (resp?.status === 200) await r.update()
					} catch (_) {
						// just ignore
					}
				}, 120_000)
			}
		},
	})

	onMount(async () => {
		await loadLocale(locale)
		setDefaultOptions({ locale: dateLocaleMap[locale] })
		setLocaleLoaded(true)
	})

	return (
		<Show when={localeLoaded()}>
			<TypesafeI18n locale={locale}>
				<AuthProvider>
					<div innerHTML={svgSprite} style="display: none" />
					{props.children}
				</AuthProvider>
			</TypesafeI18n>
		</Show>
	)
}
