import type { AccentHue } from "@repo/fika-design-system/color"
import type { IconName } from "@repo/fika-design-system/icon"
import { For, Show } from "solid-js"
import { Box } from "../components/box"
import { LinkButton } from "../components/button"
import { NavLink } from "../components/navLink"
import { headerClass, slashClass, textClass } from "./navigation.css"

type Link = {
	label: string
	href: string
}

type Button = Link & {
	iconName: IconName
}

type Props = {
	buttons?: Link[]
	title: string
	hue: AccentHue
	rightAction?: Button
}
export function Navigation(props: Props) {
	return (
		<Box justifyContent="space-between" gap="12" flexDirection="row">
			<nav class={headerClass}>
				<NavLink hue={props.hue} href="/" iconName="fika" noScroll>
					fika
				</NavLink>
				<For each={props.buttons}>
					{(button) => (
						<>
							<span class={slashClass}>»</span>
							<NavLink href={button.href} hue={props.hue} noScroll>
								{button.label}
							</NavLink>
						</>
					)}
				</For>
				<span class={slashClass}>»</span>
				<span class={textClass}>{props.title}</span>
			</nav>
			<Show when={props.rightAction}>
				{(button) => (
					<LinkButton
						href={button().href}
						hue={props.hue}
						iconName={button().iconName}
						kind="ghost"
					>
						{button().label}
					</LinkButton>
				)}
			</Show>
		</Box>
	)
}
