type SlugifiedURL = string

export function isValidUrl(url: string): boolean {
	// Assuming the ULID part is always 26 characters long.
	// Adjust the regex if your slug part has specific constraints
	const regex = /^.+-[0-9A-HJKMNP-TV-Z]{26}$/
	return regex.test(url)
}

export function splitUrl(url: SlugifiedURL | null | undefined): string | null {
	if (!url) return null
	if (!isValidUrl(url)) throw new Error("Invalid URL")

	const lastIndex = url.lastIndexOf("-")

	return url.substring(lastIndex + 1)
}

function slugify(title: string): string {
	return title
		.toLowerCase()
		.normalize("NFKD") // Normalize the string for compatibility
		.replace(/[^\w\p{L}\p{N}]+/gu, "-") // Replace non-word characters and punctuation with dashes
		.replace(/(^-|-$)/g, "") // Remove leading or trailing dashes
}

export function generateUrl(title: string, ulid: string): SlugifiedURL {
	const slug = slugify(title)
	return `${slug}-${ulid}`
}
