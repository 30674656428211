import { isValid } from "@/context/hanko"
import { mutators } from "@/data/mutators"
import type { SessionDetail } from "@teamhanko/hanko-elements"
import { type ExperimentalNoIndexDiff, Replicache } from "replicache"
import { batch, createEffect, createSignal, onCleanup } from "solid-js"
import { unwrap } from "solid-js/store"
import { db } from "./database"
import { sync } from "./sync"

const BACKEND_URL = import.meta.env.VITE_FIKA_BACKEND_OPEN_URL

function loadDiffs(diffs: ExperimentalNoIndexDiff) {
	for (const diff of diffs) {
		const [namespace, id] = diff.key.split("/")

		const table = db[namespace as keyof typeof db]
		const op = diff.op
		let value = null
		if (!table) continue

		if (op === "del") {
			table.del(id!)
		} else {
			value = diff.newValue as any
			table.upsert(id!, value)
		}
	}
}

function bookmarkedStoriesBatch(diffs: ExperimentalNoIndexDiff) {
	const adds = []
	const dels = []

	for (const diff of diffs) {
		const [_namespace, id] = diff.key.split("/")
		const op = diff.op

		if (op === "del" || op === "change") {
			dels.push(id!)
		}

		if (op === "add" || op === "change") {
			const story = db.story.get(id!)
			if (!story) continue
			if (!story?.backend) continue // skip optimistic models

			const bookmark = db.bookmark.findBy({ story_id: id! })
			if (!bookmark) continue

			const unwrappedStory = unwrap(story)
			unwrappedStory.truncated_body = (diff.newValue as any).truncated_body

			adds.push(unwrappedStory)
		}
	}

	return { adds, dels }
}

export function initReplicache(session: SessionDetail) {
	const [loaded, setLoaded] = createSignal(false)
	const sessionValid = isValid(session)
	const pushURL = sessionValid
		? `${BACKEND_URL}/api/replicache/push`
		: undefined
	const auth = `Bearer ${session.jwt}`
	const licenseKey = import.meta.env.VITE_REPLICACHE_KEY
	const logLevel = import.meta.env.VITE_ENV === "development" ? "debug" : "info"

	function pullURL(namespace: string) {
		return sessionValid
			? `${BACKEND_URL}/api/replicache/pull?namespace=${namespace}`
			: undefined
	}

	const replicache = new Replicache({
		name: `light-${session.userID}`,
		licenseKey,
		pullURL: pullURL("light"),
		pushURL,
		auth,
		mutators,
		logLevel,
	})

	// Override pusher to force a pull after push
	const originalPusher = replicache.pusher
	replicache.pusher = async (req, id) => {
		const result = await originalPusher(req, id)
		requestAnimationFrame(() => {
			replicache.pull()
		})
		return result
	}

	const heavyReplicache = new Replicache({
		name: `heavy-${session.userID}`,
		licenseKey,
		pullURL: pullURL("heavy"),
		auth,
		logLevel,
	})

	replicache.experimentalWatch(
		(diffs) => {
			batch(() => {
				loadDiffs(diffs)
				setLoaded(true)
			})
			sync.postMessage({ type: "syncPublishersAndPosts", diffs })
		},
		{ initialValuesInFirstDiff: true },
	)

	// once the light data is loaded, start watching for heavy data
	createEffect(() => {
		if (loaded()) {
			heavyReplicache.experimentalWatch(
				(diffs) => {
					const batch = bookmarkedStoriesBatch(diffs)
					sync.postMessage({ type: "syncBookmarkedStories", batch })
				},
				{ initialValuesInFirstDiff: true },
			)
		}
	})

	onCleanup(() => {
		replicache.close()
	})

	return { replicache, heavyReplicache, db, loaded }
}
