import { z } from "zod"
import { schemaToJson } from "./schemaToJson.js"

const baseSchema = z.object({
	id: z.string(),
	row_version: z.number().optional(),
	backend: z.boolean().optional(),
})

const SelectBookmarkSchema = z.object({
	...baseSchema.shape,
	story_id: z.string(),
	user_id: z.string(),
	bookmarked_at: z.date(),
	bookmarked_iso: z.string().date(),
})
export const ApiBookmarkSchema = schemaToJson(SelectBookmarkSchema)
export type SelectBookmark = z.infer<typeof SelectBookmarkSchema>
export type ApiBookmark = z.infer<typeof ApiBookmarkSchema>

export const SelectEntrySchema = z.object({
	...baseSchema.shape,
	story_id: z.string(),
	publisher_id: z.string(),
	published_at: z.date().nullable(),
})
export const ApiEntrySchema = schemaToJson(SelectEntrySchema)
export type SelectEntry = z.infer<typeof SelectEntrySchema>
export type ApiEntry = z.infer<typeof ApiEntrySchema>

export const LogSchema = z.object({
	...baseSchema.shape,
	title: z.string(),
	status: z.string().nullable(),
	created_at: z.date(),
})
export const ApiLogSchema = schemaToJson(LogSchema)
export type SelectLog = z.infer<typeof LogSchema>
export type ApiLog = z.infer<typeof ApiLogSchema>

export const PostSchema = z.object({
	...baseSchema.shape,
	title: z.string().nullable(),
	description: z.string().nullable(),
	json: z.string(),
	published_at: z.date().nullable(),
	created_at: z.date().nullable(),
	image_header_url: z.string().nullable(),
	pinned_at: z.date().nullable(),
	canonical_url: z.string().nullable(),
	meta_title: z.string().nullable(),
	meta_description: z.string().nullable(),
	bookmark_id: z.string().nullish(),
})
export const ApiPostSchema = schemaToJson(PostSchema)
export type SelectPost = z.infer<typeof PostSchema>
export type ApiPost = z.infer<typeof ApiPostSchema>

export const PublisherSchema = z.object({
	...baseSchema.shape,
	url: z.string(),
	dc_title: z.string().nullable(),
	dc_description: z.string().nullable(),
	logo_url: z.string().nullable(),
	image_url: z.string().nullable(),
	dc_created_at: z.date().nullable(),
})
export const ApiPublisherSchema = schemaToJson(PublisherSchema)
export type SelectPublisher = z.infer<typeof PublisherSchema>
export type ApiPublisher = z.infer<typeof ApiPublisherSchema>

export const RecentPublisherSchema = z.object({
	...baseSchema.shape,
	publisher_id: z.string(),
})
export const ApiRecentPublisherSchema = schemaToJson(RecentPublisherSchema)
export type SelectRecentPublisher = z.infer<typeof RecentPublisherSchema>
export type ApiRecentPublisher = z.infer<typeof ApiRecentPublisherSchema>

export const StorySchema = z.object({
	...baseSchema.shape,
	url: z.string(),
	dc_title: z.string().nullable(),
	dc_description: z.string().nullable(),
	logo_url: z.string().nullable(),
	image_url: z.string().nullable(),
	feed_url: z.string().nullable(),
	dc_created_at: z.date().nullable(),
	metadated_at: z.date().nullable(),
	truncated_body: z.string().optional().nullable(),
})
export const ApiStorySchema = schemaToJson(StorySchema)
export type SelectStory = z.infer<typeof StorySchema>
export type ApiStory = z.infer<typeof ApiStorySchema>

export const SubscriptionSchema = z.object({
	...baseSchema.shape,
	publisher_id: z.string(),
	created_at: z.date().nullable(),
	silenced: z.boolean(),
})
export const ApiSubscriptionSchema = schemaToJson(SubscriptionSchema)
export type SelectSubscription = z.infer<typeof SubscriptionSchema>
export type ApiSubscription = z.infer<typeof ApiSubscriptionSchema>

export const UserSchema = z.object({
	...baseSchema.shape,
	email: z.string(),
	jwt: z.string(),
	name: z.string().nullable(),
	title: z.string().nullable(),
	share_bookmarks: z.boolean(),
	description: z.string().nullable(),
	blog_footer: z.string().nullish(),
	blog_header: z.string().nullish(),
	email_digest: z.string().nullable(), // TODO: String enum?
	created_at: z.date().nullable(),
})
export const ApiUserSchema = schemaToJson(UserSchema)
export type SelectUser = z.infer<typeof UserSchema>
export type ApiUser = z.infer<typeof ApiUserSchema>

export const PostStatSchema = z.object({
	...baseSchema.shape,
	recentViews: z.number(),
	recentVisitors: z.number(),
})
export const ApiPostStatSchema = schemaToJson(PostStatSchema)
export type SelectPostStat = z.infer<typeof PostStatSchema>
export type ApiPostStat = z.infer<typeof ApiPostStatSchema>
