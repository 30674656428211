import { type ZodTypeAny, z } from "zod"

type JsonType<T> = T extends Date
	? string
	: T extends Array<infer U>
		? Array<JsonType<U>>
		: T extends object
			? { [K in keyof T]: JsonType<T[K]> }
			: T

export function schemaToJson<T extends ZodTypeAny>(
	schema: T,
): z.ZodType<JsonType<z.infer<T>>> {
	if (schema instanceof z.ZodObject) {
		const shape = schema.shape as Record<string, ZodTypeAny>
		const newShape = Object.fromEntries(
			Object.entries(shape).map(([key, value]) => [key, schemaToJson(value)]),
		)

		return z.object(newShape) as any
	}

	if (schema instanceof z.ZodDate) {
		return z.string().datetime({ offset: true }) as any
	}

	if (schema instanceof z.ZodArray) {
		return z.array(schemaToJson(schema.element)) as any
	}

	if (schema instanceof z.ZodOptional || schema instanceof z.ZodNullable) {
		const innerSchema = schemaToJson(schema._def.innerType)
		return schema instanceof z.ZodOptional
			? innerSchema.optional()
			: (innerSchema.nullable() as any)
	}

	return schema as any
}
