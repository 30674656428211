import { useAppContext } from "@/context/app"
import type { Log } from "@/data/models/log"
import { Box } from "@/designSystem/components/box"
import { Text } from "@/designSystem/components/text"
import {
	type AccentHue,
	type ShadeTypes,
	colorize,
} from "@repo/fika-design-system/color"
import { intlFormatDistance, isAfter, subSeconds } from "date-fns"
import { last } from "remeda"
import {
	Show,
	createEffect,
	createMemo,
	createSignal,
	onCleanup,
} from "solid-js"
import { Transition } from "solid-transition-group"
import { logRecipe } from "./logs.css"

const DURATION = 5_000

type LogProps = {
	log: Log
}
function LogComponent(props: LogProps) {
	const [show, setShow] = createSignal(false)
	const [logId, setLogId] = createSignal<string | null>(null)

	const hue = createMemo<AccentHue>(() => {
		switch (props.log.status) {
			case "Failed":
				return "rose"
			case "Progress":
				return "sand"
			case "Success":
				return "emerald"
			default:
				return "sand"
		}
	})

	function color(shade: ShadeTypes<AccentHue>) {
		return colorize(hue(), shade)
	}

	createEffect(() => {
		if (props.log && props.log.id !== logId()) {
			displayNotification(props.log.id)
		}
	})

	let timer: any

	function displayNotification(logId: string) {
		setShow(true)
		setLogId(logId)
		resetTimer()
	}

	function resetTimer() {
		clearTimeout(timer)
		timer = setTimeout(() => {
			setShow(false)
		}, DURATION)
	}
	onCleanup(() => clearTimeout(timer))

	return (
		<Transition>
			<Show when={show()}>
				<div class={logRecipe({ hue: hue() })}>
					<Box
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
						gap="8"
						fill
					>
						<Text
							fontSize="m"
							fontWeight="500"
							color={color("50")}
							lineClamp="1"
						>
							{props.log.title}
						</Text>
						<Text fontSize="m" color={color("300")} lineClamp="1">
							{intlFormatDistance(props.log.created_at, new Date())}
						</Text>
					</Box>
				</div>
			</Show>
		</Transition>
	)
}

export function Logs() {
	const { db } = useAppContext()
	const log = createMemo(() => {
		const cutoffTime = subSeconds(new Date(), 10)
		return last(
			db.log.all().filter((log) => isAfter(log.created_at, cutoffTime)),
		)
	})

	return <Show when={log()}>{(log) => <LogComponent log={log()} />}</Show>
}
