import { getTitle } from "@/lib/getTitle"
import { createMatrixParams } from "@/lib/matrixParams"
import { generateUrl } from "@/lib/slug"
import { useParams } from "@solidjs/router"

const BLOG_BASE_PATH = "/blog" as const

export const FILTER = ["published", "draft"] as const
export const SORT = ["date", "views"] as const
export const INTERVAL = {
	today: "today",
	yesterday: "yesterday",
	"1d": "24 hours",
	"7d": "7 days",
	"30d": "30 days",
	"90d": "90 days",
} as const
export const MEASURES = ["views", "visitors"] as const

export type Filter = (typeof FILTER)[number]
export type Sort = (typeof SORT)[number]
export type Interval = keyof typeof INTERVAL
export type Measure = (typeof MEASURES)[number]
export type SortField = "referrer" | "browser" | "country" | "device"
export type SortBy = "views" | "visitors"
export type SortDirection = "asc" | "desc"

interface ListParams {
	filter: Filter
	sort: Sort
	interval: Interval
	measure: Measure
	query: string
	page: number
}
const LIST_DEFAULTS: ListParams = {
	filter: "published",
	sort: "date",
	interval: "1d",
	measure: "views",
	query: "",
	page: 1,
}

type FieldParams = { page: number }
type StatsParams = {
	interval: Interval
	country: FieldParams
	referrer: FieldParams
	browser: FieldParams
	device: FieldParams
}

const STATS_DEFAULTS: StatsParams = {
	interval: "1d",
	country: { page: 1 },
	referrer: { page: 1 },
	browser: { page: 1 },
	device: { page: 1 },
}

export type SectionName = "header" | "footer"

export function createBlogRouter() {
	const params = useParams()

	const list = createMatrixParams(() => params.listParams, LIST_DEFAULTS)
	const stats = createMatrixParams(() => params.statsParams, STATS_DEFAULTS)

	function paths(listParams?: Partial<ListParams>) {
		const url = `${BLOG_BASE_PATH}${list.makeURL(listParams)}`

		return {
			url,
			new: () => `${url}/new`,
			settings: () => {
				const settingsUrl = `${url}/settings`

				return {
					url: settingsUrl,
					section: (sectionName: SectionName) =>
						`${settingsUrl}/section/${sectionName}`,
				}
			},
			stats: (statsParams?: Partial<StatsParams>) => {
				return `${url}/stats${stats.makeURL(statsParams)}`
			},
			post: (postModel?: { id: string; title: string | null }) => {
				const id = postModel
					? generateUrl(getTitle(postModel.title), postModel.id)
					: params.postId

				const postUrl = `${[url, "post", id].filter(Boolean).join("/")}`

				return {
					url: postUrl,
					settings: () => `${postUrl}/settings`,
				}
			},
		}
	}

	return {
		paths,
		listParams: list.matrixParams,
		statsParams: stats.matrixParams,
	}
}
