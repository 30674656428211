import { generate } from "@rocicorp/rails"
import type { WriteTransaction } from "replicache"
import { ulid } from "ulid"
import { BookmarkSchema } from "./models/bookmark"
import { LogSchema } from "./models/log"
import { PostSchema } from "./models/post"
import { PublisherSchema } from "./models/publisher"
import { RecentPublisherSchema } from "./models/recentPublisher"
import { StorySchema } from "./models/story"
import { SubscriptionSchema } from "./models/subscription"
import { UserSchema } from "./models/user"

const { set: setRecentPublisher } = generate(
	"recent_publisher",
	RecentPublisherSchema.parse,
)

const { init: initPublisher } = generate("publisher", PublisherSchema.parse)

const { init: initStory } = generate("story", StorySchema.parse)
const {
	delete: deletePost,
	set: setPost,
	update: updatePost,
} = generate("post", PostSchema.parse)

const { update: updateUser } = generate("user", UserSchema.parse)

const {
	init: initSubscription,
	set: setSubscription,
	delete: deleteSubscription,
} = generate("subscription", SubscriptionSchema.parse)

const { delete: deleteBookmark, init: initBookmark } = generate(
	"bookmark",
	BookmarkSchema.parse,
)

const { init: initLog } = generate("log", LogSchema.parse)

async function bookmarkStory(
	tx: WriteTransaction,
	props: {
		bookmark_id: string
		story_id: string
		user_id: string
		url: string
		metadated_at: string | null
		dc_title: string | null
		dc_description: string | null
		logo_url: string | null
		image_url: string | null
		dc_created_at: string | null
		feed_url: string | null
		dc_identifier: string | null
	},
) {
	const dc_created_at = props.dc_created_at || new Date().toISOString()

	await Promise.all([
		initStory(tx, {
			id: props.story_id,
			url: props.url,
			dc_title: props.dc_title,
			dc_description: props.dc_description,
			logo_url: props.logo_url,
			image_url: props.image_url,
			metadated_at: null,
			dc_created_at: dc_created_at,
			feed_url: props.feed_url,
			backend: false,
		}),
		initBookmark(tx, {
			id: props.bookmark_id,
			story_id: props.story_id,
			user_id: props.user_id,
			bookmarked_at: new Date().toISOString(),
			bookmarked_iso: new Date().toISOString().split("T")[0]!,
		}),
	])
}

async function subscribePublisher(
	tx: WriteTransaction,
	props: { url: string; subscription_id: string; publisher_id: string },
) {
	await Promise.all([
		initPublisher(tx, {
			id: props.publisher_id,
			url: props.url,
			dc_title: null,
			dc_description: null,
			logo_url: null,
			image_url: null,
			dc_created_at: null,
			backend: false,
		}),
		initSubscription(tx, {
			id: props.subscription_id,
			publisher_id: props.publisher_id,
			created_at: new Date().toISOString(),
			silenced: false,
		}),
		initLog(tx, {
			id: ulid(),
			title: "subscribing to publisher",
			status: "progress",
			created_at: new Date().toISOString(),
		}),
	])
}

async function syncStories(
	tx: WriteTransaction,
	props: { adapter: string; access_token: string },
) {
	await initLog(tx, {
		id: ulid(),
		title: `importing bookmarks from ${props.adapter}`,
		status: "progress",
		created_at: new Date().toISOString(),
	})
}

async function publishPost(
	tx: WriteTransaction,
	props: { id: string; published_at: string | null },
) {
	await updatePost(tx, props)
}

async function setPostHeaderPicture(
	tx: WriteTransaction,
	props: { id: string; image_header_url: string | null },
) {
	await updatePost(tx, props)
}

async function togglePostPin(
	tx: WriteTransaction,
	props: { id: string; pinned_at: string | null },
) {
	await updatePost(tx, props)
}

async function setPostCanonicalUrl(
	tx: WriteTransaction,
	props: { id: string; canonical_url: string | null },
) {
	await updatePost(tx, props)
}

async function setPostSeoMetadata(
	tx: WriteTransaction,
	props: {
		id: string
		meta_title: string | null
		meta_description: string | null
	},
) {
	await updatePost(tx, props)
}

async function updateEmailDigest(
	tx: WriteTransaction,
	props: { email_digest: string | null; id: string },
) {
	await updateUser(tx, {
		id: props.id,
		email_digest: props.email_digest,
	})
	await initLog(tx, {
		id: ulid(),
		title: "updated email digest",
		status: "success",
		created_at: new Date().toISOString(),
	})
}

export const mutators = {
	bookmarkStory,
	deleteBookmark,
	deleteSubscription,
	initBookmark,
	setRecentPublisher,
	setSubscription,
	subscribePublisher,
	syncStories,
	updateUser,
	deletePost,
	setPost,
	setPostCanonicalUrl,
	setPostHeaderPicture,
	publishPost,
	togglePostPin,
	setPostSeoMetadata,
	updateEmailDigest,
}
export type M = typeof mutators
