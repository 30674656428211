import { createClient } from "@supabase/supabase-js"
import type { Replicache } from "replicache"
import { type Accessor, createEffect } from "solid-js"
import type { User } from "./models/user"

const project = import.meta.env.VITE_SUPABASE_PROJECT
const anonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export function initPoke(
	getUser: Accessor<User | null>,
	replicaches: Replicache[],
) {
	const client = createClient(`https://${project}.supabase.co`, anonKey, {
		realtime: { params: { eventsPerSecond: 1 } },
	})

	createEffect(() => {
		const user = getUser()
		if (!user) return

		client.realtime.setAuth(user.jwt)

		client
			.channel("schema-db-changes")
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					filter: `user_id=eq.${user.id}`,
				},
				(_payload) => {
					for (const r of replicaches) {
						r.pull()
					}
				},
			)
			.subscribe((status, error) => {
				console.log(status, error)
			})
	})

	return client
}
