import type { DB } from "@/data/database"
import type { M } from "@/data/mutators"
import { initPoke } from "@/data/poke"
import { initReplicache } from "@/data/replicache"
import type { SessionDetail } from "@teamhanko/hanko-elements"
import type { Replicache } from "replicache"
import {
	type ParentProps,
	Show,
	createContext,
	createMemo,
	onMount,
	useContext,
} from "solid-js"

type App = {
	db: DB
	replicache: Replicache<M>
	session: SessionDetail
}
const AppContext = createContext<App>()

export function useAppContext() {
	const context = useContext(AppContext)

	if (!context) {
		throw new Error("useAppContext used without a Provider!")
	}

	return context
}

type Props = ParentProps & {
	session: SessionDetail
}
export function AppProvider(props: Props) {
	const { replicache, heavyReplicache, db, loaded } = initReplicache(
		props.session,
	)
	const getUser = createMemo(() => db.user.get(props.session.userID))

	onMount(() => {
		initPoke(getUser, [replicache, heavyReplicache])
	})

	return (
		<AppContext.Provider value={{ db, replicache, session: props.session }}>
			<Show when={loaded()}>{props.children}</Show>
		</AppContext.Provider>
	)
}
