/* @refresh reload */
import * as Sentry from "@sentry/solid"
import "./global.css"

import "@fontsource-variable/literata/standard.css"
import "@fontsource-variable/literata/standard-italic.css"
import "@fontsource-variable/geist-mono"
import "@fontsource-variable/bricolage-grotesque"
import "@fontsource-variable/geist"

import "@repo/fika-blog/src/content.css"
import "@/designSystem/hanko.css"

import { Route, Router } from "@solidjs/router"
import { lazy } from "solid-js"
import { render } from "solid-js/web"

import { RootLayout } from "@/designSystem/layouts/rootLayout"
import { isValidUrl } from "@/lib/slug"
import Auth from "./pages/auth"
import { Stats } from "./pages/posts/stats"

const Main = lazy(() => import("./pages/main"))
const Dashboard = lazy(() => import("./pages/dashboard/page"))
const StoryList = lazy(() => import("./pages/stories/storyList"))
const StoryItem = lazy(() => import("./pages/stories/storyItem"))
const Posts = lazy(() => import("./pages/posts/page"))
const Publications = lazy(() => import("./pages/publishers/page"))
const StoriesSettings = lazy(() => import("./pages/stories/settings"))
const PublishersSettings = lazy(() => import("./pages/publishers/settings"))
const PostsSettings = lazy(() => import("./pages/posts/settings"))
const PostsSection = lazy(() => import("./pages/posts/section"))
const AddNewPost = lazy(() => import("./pages/posts/postForm"))
const PostSettings = lazy(() => import("./pages/posts/postSettings"))
const Settings = lazy(() => import("./pages/settings/page"))
const Share = lazy(() => import("./pages/stories/share"))

const root = document.getElementById("root")

function NotFound() {
	return <div>Not found</div>
}

const slugFilters = {
	storyId: isValidUrl,
	postId: isValidUrl,
	publisherId: isValidUrl,
}

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_ENV,
	integrations: [],
})

render(
	() => (
		<Router root={RootLayout}>
			<Route path="/join" component={Auth} />
			<Route path="/" component={Main}>
				<Route path="/">
					<Route path="/" component={Dashboard} />

					<Route path="/bookmarks/:listParams?">
						<Route path="/" component={StoryList} />
						<Route path="/share" component={Share} />
						<Route path="/add" component={StoriesSettings} />
						<Route
							path="/story/:storyId"
							component={StoryItem}
							matchFilters={slugFilters}
						/>
					</Route>

					<Route path="/feeds/:listParams?">
						<Route path="/" component={Publications} />
						<Route path="/subscribe" component={PublishersSettings} />
						<Route path="/feed/:publisherId" matchFilters={slugFilters}>
							<Route path="/" component={Publications} />
							<Route
								path="/:storyId"
								component={Publications}
								matchFilters={slugFilters}
							/>
						</Route>
					</Route>

					<Route path="/blog/:listParams?">
						<Route path="/" component={Posts} />
						<Route path="/new" component={AddNewPost} />
						<Route path="/settings">
							<Route path="/" component={PostsSettings} />
							<Route path="/section/:sectionName" component={PostsSection} />
						</Route>
						<Route path="/stats/:statsParams?" component={Stats} />
						<Route path="/post/:postId" matchFilters={slugFilters}>
							<Route path="/settings" component={PostSettings} />
							<Route path="/" component={Posts} />
						</Route>
					</Route>

					<Route path="/settings">
						<Route path="/" component={Settings} />
					</Route>
				</Route>
				<Route path="*404" component={NotFound} />
			</Route>
		</Router>
	),
	root!,
)
