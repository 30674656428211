const huesAndShades = {
	base: {
		"0": "oklch(99.85% 0.002 85)",
		"1000": "oklch(12% 0.003 85)",
	},
	sand: {
		"50": "oklch(98.7% 0.0037 85)",
		"100": "oklch(97.2% 0.005 85)",
		"200": "oklch(93% 0.007 85)",
		"300": "oklch(88% 0.011 85)",
		"400": "oklch(81% 0.013 85)",
		"500": "oklch(73% 0.013 85)",
		"600": "oklch(63% 0.013 85)",
		"700": "oklch(53% 0.01 85)",
		"800": "oklch(42% 0.008 85)",
		"900": "oklch(32% 0.006 85)",
		"950": "oklch(21% 0.006 85)",
	},

	emerald: {
		"50": "oklch(99.1% 0.01 160)",
		"100": "oklch(96.6% 0.03 160)",
		"200": "oklch(91.2% 0.06 160)",
		"300": "oklch(85.2% 0.10 160)",
		"400": "oklch(77.5% 0.12 160)",
		"500": "oklch(70.5% 0.15 160)",
		"600": "oklch(57% 0.15 160)",
		"700": "oklch(47% 0.14 160)",
		"800": "oklch(39% 0.12 160)",
		"900": "oklch(28% 0.08 160)",
		"950": "oklch(21.1% 0.06 160)",
	},

	rose: {
		"50": "oklch(99.2% 0.01 5)",
		"100": "oklch(97.5% 0.03 5)",
		"200": "oklch(92.5% 0.06 5)",
		"300": "oklch(88% 0.10 5)",
		"400": "oklch(81% 0.11 5)",
		"500": "oklch(75.5% 0.15 5)",
		"600": "oklch(62% 0.15 5)",
		"700": "oklch(51.5% 0.14 5)",
		"800": "oklch(42% 0.12 5)",
		"900": "oklch(30% 0.08 5)",
		"950": "oklch(22% 0.06 5)",
	},

	indigo: {
		"50": "oklch(99% 0.01 264)",
		"100": "oklch(97% 0.03 264)",
		"200": "oklch(92% 0.06 264)",
		"300": "oklch(86.5% 0.10 264)",
		"400": "oklch(79.5% 0.15 264)",
		"500": "oklch(73.5% 0.15 264)",
		"600": "oklch(60% 0.15 264)",
		"700": "oklch(50% 0.14 264)",
		"800": "oklch(41% 0.12 264)",
		"900": "oklch(30% 0.08 264)",
		"950": "oklch(22% 0.06 264)",
	},

	orange: {
		"50": "oklch(99% 0.01 40)",
		"100": "oklch(97.5% 0.03 40)",
		"200": "oklch(92.5% 0.06 40)",
		"300": "oklch(87.5% 0.10 40)",
		"400": "oklch(80.5% 0.11 40)",
		"500": "oklch(75% 0.15 40)",
		"600": "oklch(61.5% 0.15 40)",
		"700": "oklch(51% 0.14 40)",
		"800": "oklch(42% 0.12 40)",
		"900": "oklch(30% 0.08 40)",
		"950": "oklch(22% 0.06 40)",
	},

	amber: {
		"50": "oklch(99% 0.01 88)",
		"100": "oklch(97% 0.03 88)",
		"200": "oklch(92% 0.06 88)",
		"300": "oklch(86.5% 0.10 88)",
		"400": "oklch(79.5% 0.15 88)",
		"500": "oklch(73.5% 0.15 88)",
		"600": "oklch(59.5% 0.15 88)",
		"700": "oklch(50% 0.12 88)",
		"800": "oklch(41% 0.1 88)",
		"900": "oklch(30% 0.08 88)",
		"950": "oklch(22% 0.06 88)",
	},
} as const

export const accentHues = [
	"sand",
	"emerald",
	"rose",
	"indigo",
	"orange",
	"amber",
] as const

export const noHue = "no-color"
export type NoHue = typeof noHue
export const accentHuesWithNoHue = [...accentHues, noHue] as const
export type AccentHuesWithNoHue = (typeof accentHuesWithNoHue)[number]

export type Hue = keyof typeof huesAndShades
export type AccentHue = (typeof accentHues)[number]
export type BaseHue = Exclude<Hue, AccentHue>
export type ShadeTypes<H extends Hue> = keyof (typeof huesAndShades)[H]

export type BaseColor = `${BaseHue}${ShadeTypes<BaseHue>}`
export type AccentColor = `${AccentHue}${ShadeTypes<AccentHue>}`
export type Color = BaseColor | AccentColor

const flatten: { [key: string]: string } = {}

for (const hue in huesAndShades) {
	for (const shade in huesAndShades[hue as Hue]) {
		const shades = huesAndShades[hue as Hue] as any
		flatten[`${hue}${shade}`] = shades[shade] as string
	}
}
export const color = flatten as Record<Color, string>

export type Alpha = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 9.5 | 10

export function colorize(hue: AccentHue, shade: ShadeTypes<AccentHue>) {
	return `${hue}${shade}` as AccentColor
}

export function shadeColor(hue: AccentHue) {
	return colorize.bind(null, hue)
}

export function contrastColor(
	hue: AccentHue,
	shade: ShadeTypes<AccentHue>,
	condition: boolean,
) {
	const finalShade = condition ? String(1000 - Number(shade)) : shade

	return colorize(hue, finalShade as ShadeTypes<AccentHue>)
}

export function hueVariants<T>(map: (fn: HueShadeGetter, hue: AccentHue) => T) {
	return accentHues.reduce(
		(acc, hue) => {
			acc[hue] = map(getHueColor(hue), hue)
			return acc
		},
		{} as { [K in AccentHue]: T },
	)
}

export function mapHues<T>(map: (fn: HueShadeGetter, hue: AccentHue) => T[]) {
	return accentHues.flatMap((hue) => map(getHueColor(hue), hue))
}

export function objectHueMap<T>(
	map: (fn: HueShadeGetter, hue: AccentHue) => T,
) {
	return accentHues.reduce<T>((acc, hue) => {
		const result = map(getHueColor(hue), hue)
		return Object.assign({}, acc, result)
	}, {} as T)
}

export function forEachHue(
	fn: (fn: HueShadeGetter, hue: AccentHuesWithNoHue) => void,
	noHue?: boolean,
) {
	const hues = noHue ? accentHuesWithNoHue : accentHues
	for (const hue of hues) {
		fn(getHueColor(hue), hue)
	}
}

export function alpha(colorName: string, alpha: Alpha) {
	const extractedColor = colorName.match(/\((.*?)\)/)![1]
	return `oklch(${extractedColor} / ${alpha / 10})`
}

export type HueShadeGetter = (
	shade: ShadeTypes<AccentHue>,
	fallback?: string,
) => string
export type ColorGetter = (hue: AccentHuesWithNoHue) => HueShadeGetter

export function getHueColor(hue: AccentHuesWithNoHue): HueShadeGetter {
	if (hue === noHue) {
		return (_shade: ShadeTypes<AccentHue>, fallback?: string) => {
			if (!fallback) {
				throw new Error("Fallback is required for noHue")
			}

			return fallback
		}
	}

	return (shade: ShadeTypes<AccentHue>) => color[colorize(hue, shade)]
}
