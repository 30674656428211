import type { AccentHue } from "@repo/fika-design-system/color"
import type { IconName } from "@repo/fika-design-system/icon"
import { type JSX, type ParentProps, Show, splitProps } from "solid-js"
import { Icon } from "../components/icon"
import { clampClass, iconRecipe, linkRecipe } from "./navLink.css"

type LinkProps = ParentProps &
	JSX.AnchorHTMLAttributes<HTMLAnchorElement> & {
		hue: AccentHue
		wrap?: boolean
		iconName?: IconName
	}
export function NavLink(props: LinkProps) {
	const [_, otherProps] = splitProps(props, ["hue", "iconName"])

	return (
		<a class={linkRecipe({ hue: props.hue, wrap: props.wrap })} {...otherProps}>
			<Show when={props.iconName}>
				{(iconName) => (
					<Icon name={iconName()} class={iconRecipe({ hue: props.hue })} />
				)}
			</Show>
			<span class={clampClass}>{otherProps.children}</span>
		</a>
	)
}
