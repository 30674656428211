import { useAppContext } from "@/context/app"
import { isValid } from "@/context/hanko"
import { Logs } from "@/pages/main/logs"
import { useConnectivitySignal } from "@solid-primitives/connectivity"
import { useSearchParams } from "@solidjs/router"
import type { SessionDetail } from "@teamhanko/hanko-elements"
import { type JSXElement, type ParentProps, Show } from "solid-js"
import { Box } from "../components/box"
import { LinkButton } from "../components/button"
import { Icon } from "../components/icon"
import {
	backdropClass,
	backdropEdgeClass,
	bodyRecipe,
	footerClass,
	headerRecipe,
	headerWrapperClass,
	layoutClass,
	mainClass,
	offlineClass,
	rightPanelClass,
	sessionExpiredClass,
	wrapperClass,
} from "./mainLayout.css"

type Props = ParentProps & {
	header?: JSXElement
	footer?: JSXElement
	rightPanel?: JSXElement
	editor?: boolean
}

export function MainLayout(props: Props) {
	const { session } = useAppContext()

	return (
		<div class={layoutClass}>
			<Join session={session} />
			<Offline />
			<Logs />
			<div class={wrapperClass}>
				<div class={bodyRecipe({ editor: props.editor })}>
					<Show when={props.header}>
						{(header) => (
							<header class={headerRecipe({ editor: props.editor })}>
								<div class={backdropClass} />
								<div class={backdropEdgeClass} />
								<div class={headerWrapperClass}>{header()}</div>
							</header>
						)}
					</Show>
					<main class={mainClass}>{props.children}</main>
					<Show when={props.footer}>
						{(footer) => <footer class={footerClass}>{footer()}</footer>}
					</Show>
				</div>
				<Show when={props.rightPanel}>
					{(rightPanel) => (
						<aside class={rightPanelClass}>{rightPanel()}</aside>
					)}
				</Show>
			</div>
		</div>
	)
}

function Offline() {
	const isOnline = useConnectivitySignal()

	return (
		<Show when={!isOnline()}>
			<Box
				padding={{ inline: "8", block: "4" }}
				background="rose800"
				alignItems="center"
				justifyContent="center"
				flexDirection="row"
				gap="8"
				borderRadius="4"
				class={offlineClass}
			>
				<Icon name="wifi-off" size="18" color="rose100" />
			</Box>
		</Show>
	)
}

type JoinProps = {
	session: SessionDetail
}
function Join(props: JoinProps) {
	const isOnline = useConnectivitySignal()
	const [params, _] = useSearchParams()

	return (
		<Show when={!isValid(props.session) && isOnline()}>
			<Box
				class={sessionExpiredClass}
				flexDirection="row"
				gap="12"
				alignItems="center"
				justifyContent="center"
			>
				your session has expired
				<LinkButton
					hue="orange"
					href={`/join?origin=${params.origin}`}
					iconName="fingerprint"
				>
					join
				</LinkButton>
			</Box>
		</Show>
	)
}
